.logo-text {
  color: #0065a4;
}

.navbar {
  margin-bottom: 20px;
}

.logo {
  float: left;
  margin-right: 5px;
}

.logo-top {
  font-weight: 300;
  font-size: 13px;
  letter-spacing: 1px;
  color: #0065a4;
}

.logo-bottom {
  font-weight: 500;
  letter-spacing: 1.5px;
  font-size: 17px;
  color: #95a0a9;
}

.logo-small {
  font-size: 12px;
  margin-top: -2px;
}

.navbar {
  margin-bottom: 20px;
}

.navbar-brand {
  min-width: 225px;
}

.navbar-header {
  height: 80px;
}

.navbar-nav > li > a {
  line-height: 50px;
}
